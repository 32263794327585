import React from "react";
import AuthMiddleware from "./auth/middleware";
import { RequireAuth } from "./auth/routeControll";
import GlobalStore, { createStore } from "./store";
import AuthReducer, { authObject } from "./auth/reducer";
import LoadingScreen from "./components/essential/LoadingScreen";
import ErrorBoundary from "./components/essential/ErrorBoundary";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";

import "./App.css";

const MotorClaimInsuranceForm = React.lazy(() =>
  import("./components/pages/MotorClaimInsuranceForm"),
);
const PartSupplierInvoiceForm = React.lazy(() =>
  import("./components/pages/PartSupplierInvoiceForm"),
);
const PartSupplierEstimateForm = React.lazy(() =>
  import("./components/pages/PartSupplierEstimateForm"),
);
const SalvagePartsRegisterForm = React.lazy(() =>
  import("./components/pages/SalvagePartsRegisterForm"),
);
const LoginPage = React.lazy(() => import("./components/pages/LoginPage"));
const InboxPage = React.lazy(() => import("./components/pages/InboxPage"));
const Onboarding = React.lazy(() => import("./components/pages/Onboarding"));
const CustomerView = React.lazy(() => import("./components/pages/CustomerView"));
const AfterRepairForm = React.lazy(() => import("./components/pages/AfterRepairForm"));
const WorkshopMapping = React.lazy(() => import("./components/pages/WorkshopMapping"));
const DuringRepairForm = React.lazy(() => import("./components/pages/DuringRepairForm"));
const BeforeRepairForm = React.lazy(() => import("./components/pages/BeforeRepairForm"));
const DrawingManagement = React.lazy(() => import("./components/pages/DrawingManagement"));
const OverviewStatistic = React.lazy(() => import("./components/pages/OverviewStatistic"));
const NonMotorClaimForm = React.lazy(() => import("./components/pages/NonMotorClaimForm"));
const WorkShopManagement = React.lazy(() => import("./components/pages/WorkShopManagment"));
const SurveyorManagement = React.lazy(() => import("./components/pages/SurveyorManagment"));
const SurveyorReportForm = React.lazy(() => import("./components/pages/SurveyorReportForm"));
const MotorClaimsOverview = React.lazy(() => import("./components/pages/MotorClaimsOverview"));
const ReportFilesOverview = React.lazy(() => import("./components/pages/ReportFilesOverview"));
const WorkshopCaseListView = React.lazy(() => import("./components/pages/WorkshopCaseListView"));
const SurveyorCaseListView = React.lazy(() => import("./components/pages/SurveyorCaseListView"));
const ThirdPartyReportForm = React.lazy(() => import("./components/pages/ThirdPartyReportForm"));
const ClaimCaseWorkshopView = React.lazy(() => import("./components/pages/ClaimCaseWorkshopView"));
const ClaimCaseSurveyorView = React.lazy(() => import("./components/pages/ClaimCaseSurveyorView"));

function Suspense({ children }) {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<LoadingScreen />}>{children}</React.Suspense>
    </ErrorBoundary>
  );
}

function LandingPage() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#f3f3f3",
        height: "100vh",
      }}
    >
      <div
        style={{
          padding: "12px 20px",
          background: "white",
          textAlign: "center",
          borderRadius: "5px",
        }}
      >
        <div style={{ fontSize: "2em" }}>Insurance Claim Management System.</div>
        <Link to="/portal/intimation?type=motor">Portal</Link>
      </div>
    </div>
  );
}

function App() {
  const load = createStore(AuthReducer, {
    ...authObject,
  });

  return (
    <GlobalStore load={load}>
      <AuthMiddleware>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense>
                  <LandingPage />
                </Suspense>
              }
            />
            <Route
              path="/login"
              exact
              element={
                <Suspense>
                  <LoginPage />
                </Suspense>
              }
            />
            <Route
              path="/portal"
              exact
              element={
                <RequireAuth>
                  <Suspense>
                    <InboxPage />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              exact
              path="/portal/statistic"
              element={
                <RequireAuth>
                  <Suspense>
                    <OverviewStatistic />
                  </Suspense>
                </RequireAuth>
              }
            ></Route>
            <Route exact path="/portal/org">
              <Route
                path="surveyors"
                exact
                element={
                  <RequireAuth>
                    <Suspense>
                      <SurveyorManagement />
                    </Suspense>
                  </RequireAuth>
                }
              />
              <Route
                path="workshops"
                exact
                element={
                  <RequireAuth>
                    <Suspense>
                      <WorkShopManagement />
                    </Suspense>
                  </RequireAuth>
                }
              />
              <Route
                path="workshops/map"
                exact
                element={
                  <RequireAuth>
                    <Suspense>
                      <WorkshopMapping />
                    </Suspense>
                  </RequireAuth>
                }
              />
              <Route
                path="drawing"
                exact
                element={
                  <RequireAuth>
                    <Suspense>
                      <DrawingManagement />
                    </Suspense>
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="/portal/motor-claims-overview"
              element={
                <RequireAuth>
                  <Suspense>
                    <MotorClaimsOverview />
                  </Suspense>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/portal/report-queue"
              element={
                <RequireAuth>
                  <Suspense>
                    <ReportFilesOverview />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/portal/register-salvage-parts/:registrationId"
              element={
                <RequireAuth>
                  <Suspense>
                    <SalvagePartsRegisterForm />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/portal/:filter"
              element={
                <RequireAuth>
                  <Suspense>
                    <InboxPage />
                  </Suspense>
                </RequireAuth>
              }
            >
              <Route
                path=":caseId"
                element={
                  <RequireAuth>
                    <Suspense>
                      <InboxPage />
                    </Suspense>
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="/views">
              <Route path="public">
                {/* "/views/public/for-customer" is for backward compatibility */}
                {/* To be removed later */}
                <Route
                  path="for-customer/form"
                  element={
                    <Suspense>
                      <MotorClaimInsuranceForm />
                    </Suspense>
                  }
                />
                <Route
                  path="for-customer/non-motor-form"
                  element={
                    <Suspense>
                      <NonMotorClaimForm />
                    </Suspense>
                  }
                />
                <Route path="claimcase">
                  <Route
                    path="for-customer/form"
                    element={
                      <Suspense>
                        <MotorClaimInsuranceForm />
                      </Suspense>
                    }
                  />
                  <Route
                    path="for-customer/non-motor-form"
                    element={
                      <Suspense>
                        <NonMotorClaimForm />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>

              <Route path="private">
                <Route
                  path="surveyorview/:surveyorId"
                  element={
                    <Suspense>
                      <SurveyorCaseListView />
                    </Suspense>
                  }
                />
                <Route
                  path="workshopview/:workshopId"
                  element={
                    <Suspense>
                      <WorkshopCaseListView />
                    </Suspense>
                  }
                />
                <Route
                  path="customerview/:claimCaseId"
                  element={
                    <Suspense>
                      <CustomerView />
                    </Suspense>
                  }
                />

                <Route path="claimcase">
                  {/* Duplicated routes */}
                  <Route
                    path="for-customer/form"
                    element={
                      <Suspense>
                        <MotorClaimInsuranceForm />
                      </Suspense>
                    }
                  />
                  <Route
                    path="for-customer/non-motor-form"
                    element={
                      <Suspense>
                        <NonMotorClaimForm />
                      </Suspense>
                    }
                  />
                  {/* Duplicated routes */}

                  <Route
                    path="for-surveyor/:appointmentId"
                    exact
                    element={
                      <Suspense>
                        <ClaimCaseSurveyorView />
                      </Suspense>
                    }
                  />
                  <Route
                    path="for-workshop/:repairJobId"
                    element={
                      <Suspense>
                        <ClaimCaseWorkshopView />
                      </Suspense>
                    }
                  />
                  <Route
                    path="for-surveyor/:appointmentId/form"
                    element={
                      <Suspense>
                        <SurveyorReportForm />
                      </Suspense>
                    }
                  />
                  <Route
                    path="third-party/:thirdPartyReportId/form"
                    exact
                    element={
                      <Suspense>
                        <ThirdPartyReportForm />
                      </Suspense>
                    }
                  />
                  <Route
                    path="for-workshop/:repairJobId/before-repair"
                    element={
                      <Suspense>
                        <BeforeRepairForm />
                      </Suspense>
                    }
                  />
                  <Route
                    path="for-workshop/:repairJobId/during-repair"
                    element={
                      <Suspense>
                        <DuringRepairForm />
                      </Suspense>
                    }
                  />
                  <Route
                    path="for-workshop/:repairJobId/after-repair"
                    element={
                      <Suspense>
                        <AfterRepairForm />
                      </Suspense>
                    }
                  />
                  <Route
                    path="for-workshop/:repairJobId/part-supplier-estimate"
                    element={
                      <Suspense>
                        <PartSupplierEstimateForm />
                      </Suspense>
                    }
                  />
                  <Route
                    path="for-workshop/:repairJobId/part-supplier-invoice"
                    element={
                      <Suspense>
                        <PartSupplierInvoiceForm />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
            </Route>

            <Route
              path="/onboarding/:claimCaseId"
              element={
                <Suspense>
                  <Onboarding />
                </Suspense>
              }
            />
          </Routes>
        </Router>
      </AuthMiddleware>
    </GlobalStore>
  );
}

export default App;
